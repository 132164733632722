export const urls = {
  merchants: "business/api/merchants",
  freebiesDataUrl: "business/loyalty/overview/freebies-data",
  loyaltyOverviewDataUrl: "business/loyalty/overview/data",
  loyaltyOverviewGraphDataUrl: "business/loyalty/overview/graph-data",
  getLoyaltyListDataUrl: "business/loyalty/list",
  activeCustomerSales: "business/loyalty/getActiveCustomerSales",
  redemptionSales: "business/loyalty/getRedemptionSales",
  memberSales: "business/loyalty/getMemberSales",
  overallSalesData: "business/loyalty/getOverallSalesAvgVisitsData",
  anonymousChk: "business/loyalty/getAnonymousChk",
  programSales: "business/loyalty/getProgramSales",
  getcardAndProgramData: "business/loyalty/getcardAndProgramData",
  programUserCount: "business/loyalty/program/user/count",
  getEnrolledCustomerCount:"business/loyalty/getEnrolledCustomerCount/loyalty/loyalty_id/merchant/merchant_id",
  getActiveCustomerCount:"business/loyalty/getActiveCustomerCount/loyalty/loyalty_id/merchant/merchant_id",
  getLoyaltyMemberCount:"business/loyalty/getLoyaltyMemberCount/loyalty/loyalty_id/merchant/merchant_id",
  merchantListUrl: "business/api/merchants/list",
  loyaltySignupChannelsData: "business/loyalty/getLoyaltySignUpChannelsData",
  loyaltyMembershipSignupData:"business/loyalty/getLoyaltyMembershipSignUpData",
  activeLoyaltyMemberData: "business/loyalty/getActiveLoyaltyMemberData",
  redemptionsData: "business/loyalty/getRedemptionsData",

  /**coupon-report-urls */
  getCouponReportDataUrl: "business/loyalty/coupon-report/fetch-data",
  getCouponReportsUrl: "business/loyalty/coupon-redemptions-data",
  updateCouponsEnableDateUrl: "business/loyalty/coupon-redemptions-enable",
  updateCouponsExpiryDateUrl: "business/loyalty/coupon-redemptions-expiry",
  blockCouponsUrl: "business/loyalty/coupon-redemptions-block",
  getActiveCountUrl: "business/loyalty/active-coupon",
  getExpiredCountUrl: "business/loyalty/expired-coupon",
  getTotalCouponsReportUrl: "business/loyalty/coupon-redemptions-total",
  getRedeemedCountUrl: "business/loyalty/redeemed-coupon",
  getBlockedCountUrl: "business/loyalty/blocked-coupon",
  exportCouponReportUrl: "business/loyalty/export-coupon-report",

  /**points-report-url */
  getLoyaltyPointsRedeemedUrl: "business/loyalty/points-report-data",
  getPointsReportPaginateUrl: "business/loyalty/points-report-paginate",
  getTotalPointsOfferedUrl: "business/loyalty/total-points-offered",
  getCustomersWithPointsUrl: "business/loyalty/customers-with-points",
  getCustomersRedeemedPointsUrl: "business/loyalty/customers-redeemed-points",
  getTotalSpentThroughLoyaltyUrl:
    "business/loyalty/total-spent-through-loyalty",
  getTotalAmountPaidByPointsUrl: "business/loyalty/total-amount-paid-by-points",
  getExportPointsDataUrl: "business/loyalty/export-data",

  /**loyalty approval */
  getLoyaltySchemeUrl:`business/loyalty/schemes/list`,
  getLoyaltySchemeDataUrl:`business/loyalty/schemes/data`,
  getLoyaltySchemeTransactionUrl:`business/loyalty/schemes/transactions`,
  getLoyaltyCardApprovalUrl: `business/loyalty/cards/list`,
  getCardSignupDataUrl: 'business/loyalty/approve/list',
  approveCardSignupUrl: 'business/loyalty/approve/approve',
  exportCardSignupDataUrl: `business/loyalty/approve/export`,
  getLoyaltyCardEnrollmentData: `business/loyalty/card/enrollment-criteria/data`,
  getLoyaltyCardEnrollmentStore: `business/loyalty/card/enrollment-criteria/store`,

  /**tier report */
  tierWiseCustomerExportToExcel: "business/loyalty/export-tier-wise-report",
  tierReportUrl: "business/loyalty/get-tier-wise-report",
  tierWiseCustomerListUrl: "business/loyalty/get-tier-wise-customer-list",
  getloyalitycardListUrl: "business/loyalty/get-card-payments",
  // reDirecting Routes
  
  // third party 
  getThirdPartyCouponsUrl :"business/loyalty/thirdparty-coupon-report",
  updateCouponsDataUrl:"business/loyalty/thirdparty-coupon-update",
  getSyncedCatelogues: "business/loyalty/synced-catalogues",

  //card subscription
  configCardSubScriptionUrl:"business/loyalty/card-subscription/config",
  dataCardSubScriptionUrl: "business/loyalty/card-subscriptions/data",

  getConfigSegmentsList: 'business/customers/fetch/segments/v2', //?brandId=18&limit=10000&page=1'
  getOffersListUrl: 'business/offers/list',

  getStampSpendHybridProgramUrl:(merchantId) =>`/merchant/${merchantId}/loyalty-challenges/scheme-list`,
  getStampSpendHybridProgram:(merchantId) =>`/merchant/${merchantId}/loyalty-challenges/scheme`,
  createStampSpendHybridProgramUrl: (merchantId) => `merchant/${merchantId}/loyalty-challenges/create`,
  updateStampSpendHybridProgramUrl: (merchantId) => `merchant/${merchantId}/loyalty-challenges/update`,
  deleteStampSpendHybridProgramUrl: (merchantId) => `merchant/${merchantId}/loyalty-challenges/delete`,

  // Transition 
  transitionUrl: "business/loyalty/card-transitions",
  transitionsConfigUrl:"business/loyalty/card-transitions/config",

  /* Non Transactional Loyalty Activity */
    /* CRUD Operations */
    getLoyaltyActivityListUrl: '/business/loyalty/config/activities/list',
    createLoyaltyActivityUrl: '/business/loyalty/config/activities/create',
    editLoyaltyActivity: 'business/loyalty/config/activities/edit',
    deleteLoyaltyActivityUrl: (id) => `/business/loyalty/config/activities/delete/${id}`,
    updateActivityStatusUrl: (id) => `/business/loyalty/config/activities/update-status/${id}`,
    getDetailsUrl: (id) => `business/loyalty/config/activities/details/${id}`,

    /* Overview */ 
    getLoyaltyActivityParticipantsListUrl: 'business/loyalty/activities/overview',
    getLineChartParticipationDateUrl: 'business/loyalty/activities/graph',

    /* Participations */
    getLoyaltyActivitiesListUrl: 'business/loyalty/activities/list',
    getLoyaltyActivityParticipationsUrl: 'business/loyalty/activities/participants',
    getLoyaltyFraudDetectionRuleUrl: (merchantId) => `merchant/${merchantId}/fraud-detection/data`,
    updateFraudDetectionUrl:(merchantId) => `merchant/${merchantId}/fraud-detection/save`,
    
};
