import { actionUrl } from "@/apiClient";
export const moduleSlug = 'loyalty';
export const moduleAuth = 'Loyalty';
import LoyaltyPicker from "./components/LoyaltyPicker.vue";
export const children = [
  {
    path: '',
    component: () => import('./pages/LoyaltyDashboard.vue'),
    meta: {
      title: 'Loyalty Dashboard',
      linkTitle: 'Dashboard',
      linkIcon: 'home',
      showInNav: true,
      permission: "Loyalty.Dashboard",
    }
  },
  {
    path: 'freebie/overview',
    component: () => import('./pages/LoyaltyFreebieOverview.vue'),
    meta: {
      title: 'Freebies',
      linkIcon: 'database',
      showInNav: true,
      permission: "Loyalty.FreebiesReport",
    }
  },
  {
    path: 'tiers/report',
    component: () => import('./pages/LoyaltyTierWiseReport.vue'),
    meta: {
      title: 'Tiers',
      linkIcon: 'file-text',
      showInNav: true,
      permission: "Loyalty.TiersReport",
    }
  },
  {
    path: 'points/report',
    component: () => import('./pages/LoyaltyPointsReport.vue'),
    meta: {
      title: 'Points',
      linkIcon: 'file-powerpoint-o',
      showInNav: true,
      permission: "Loyalty.PointsReport",
    }
  },
  {
    path: 'coupon/report',
    component: () => import('./pages/LoyaltyCouponReport.vue'),
    meta: {
      title: 'Coupons',
      linkIcon: 'gift',
      showInNav: true,
      permission: "Loyalty.CouponsReport",
    }
  },
  {
    path: 'approve-cards',
    component: () => import('./pages/LoyaltyApprovalPlatform.vue'),
    meta: {
      title: 'Signups',
      linkIcon: 'user-plus',
      showInNav: true,
      permission: "Loyalty.CardApproval",
    }
  },
  {
    path: 'cards-payments',
    component: () => import('./pages/LoyaltyCardsPayments.vue'),
    meta: {
      title: 'Payments',
      linkIcon: 'credit-card',
      showInNav: true,
      permission: "Loyalty.CardPaymentReport",
    }
  },
  {
    path: 'challenges',
    component: () => import('./pages/LoyaltySchemeChallenge.vue'),
    meta: {
      title: 'Challenges',
      linkIcon: 'bullseye',
      showInNav: true,
      permission: "Loyalty.ChallengesReport",
    }
  },
  {
    path: 'subscriptions',
    component: () => import('./pages/LoyaltyCardSubScription.vue'),
    meta: {
      title: 'Subscriptions',
      linkIcon: 'fa fa-cc"',
      showInNav: true,
      permission: "Loyalty.Subscriptions",
    }
  },
  {
    path: 'tiers/transitions',
    component: () => import('./pages/Transitions.vue'),
    meta: {
      showInNav: false,
    }
  },
  {
    path: 'config/Programs',
    component: () => import('./pages/programs/programs.vue'),
    meta: {
      title: 'Challenges',
      linkIcon: '',
      showInNav: false,
      permission: 'Loyalty.Config.Challenges.Read'
    }
  },
  {
    path: 'config/programs/create',
    component: () => import('./pages/programs/createProgram.vue'),
    meta: {
      title: 'Challenge Create',
      linkIcon: '',
      showInNav: false,
      permission: 'Loyalty.Config.Challenges.Write'
    }
  },
  {
    path: 'config/programs/edit/:id',
    component: () => import('./pages/programs/createProgram.vue'),
    meta: {
      title: 'Challenge Update',
      linkIcon: '',
      showInNav: false,
      permission: 'Loyalty.Config.Challenges.Write'
    }
  },
  {
    path: 'config/challenges',
    component: () => import('./pages/LoyaltyChallenges.vue'),
    meta: {
      title: 'Challenges',
      linkIcon: '',
      showInNav: false,
      permission: 'Loyalty.Config.Challenges.Read'
    }
  },
  {
    path: 'config/challenges/create',
    component: () => import('./pages/challenges/CreateChallenge.vue'),
    meta: {
      title: 'Challenge Create',
      linkIcon: '',
      showInNav: false,
      permission: 'Loyalty.Config.Challenges.Write'
    }
  },
  {
    path: 'config/challenges/edit/:id',
    component: () => import('./pages/challenges/CreateChallenge.vue'),
    meta: {
      title: 'Challenge Update',
      linkIcon: '',
      showInNav: false,
      permission: 'Loyalty.Config.Challenges.Write'
    }
  },
  {
    path: 'config',
    component: () => import('@/components/Configuration/Configurations.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Configurations',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Dashboard",
    }
  },
  {
    path: 'config/card-enrollment',
    component: () => import('./components/Enrollment/CardEnrollmentCriteria.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Card Enrollment Criteria',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableCardEnrollment',
      permission: "Loyalty.Config.Enrollment",
    }
  },
  {
    path: 'config/card-enrollment/add',
    component: () => import('./components/Enrollment/CriteriaForm.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Add Card Enrollment Criteria',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableCardEnrollment',
      permission: "Loyalty.Config.Enrollment",
    }
  },
  {
    path: 'config/card-enrollment/edit/:id',
    component: () => import('./components/Enrollment/CriteriaForm.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Edit Card Enrollment Criteria',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableCardEnrollment',
      permission: "Loyalty.Config.Enrollment",
    }
  },
  {
    path: 'config/activities',
    component: () => import('./pages/LoyaltyActivity.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activity',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Config.Activities",
    },
  },
  {
    path: 'config/activities/create',
    component: () => import('./pages/LoyaltyActivityCreateOrEdit.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activity',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Config.Activities",
    },
  },
  {
    path: 'config/activities/update',
    component: () => import('./pages/LoyaltyActivityCreateOrEdit.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activity',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Config.Activities",
    },
  },
  {
    path: 'activities/overview',
    component: () => import('./pages/LoyaltyActivityOverview.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activities Overview',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Activities",
    },
  },
  {
    path: 'activities/participationInsights',
    component: () => import('./pages/LoyaltyActivityInsights.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activities Insights',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Activities",
    },
  },
  {
    path: 'config/fraud/rules',
    component: () => import('./pages/LoyaltyFraudDetectionRule.vue'),
    meta: {
      title: 'Loyalty Fraud Detection Rule',
      linkIcon: 'database',
      showInNav: false,
      permission: "Loyalty.Dashboard",
    }
  },
  {
    path: "",
    meta: {
      title: "Activities",
      linkTitle: "Goals",
      linkIcon: "tasks",
      showInNav: true,
      excludeInRoute: true,
      menu: [
        {
          path: "activities/overview",
          title: 'Overview',
          permission: "Loyalty.Activities",

        },
        {
          path: "activities/participationInsights",
          title: 'Participants',
          permission: "Loyalty.Activities",
        },
      ],
    },
  },
];
export const quickLinks = [
  {
    name: 'Configurations',
    link: `/${moduleSlug}/config`,
    new: true,
    brandFlag: 'enableConfigurations',
    permission: "Loyalty.Dashboard",
  },
  {
    name: 'Programs',
    link: `/${moduleSlug}/config/programs`,
    new: true,
    permission: 'Loyalty.Config.Programs'
  },
  {
    name: 'Challenges',
    link: `/${moduleSlug}/config/challenges`,
    new: true,
    permission: 'Loyalty.Config.Challenges'
  },
  {
    name: 'Activities',
    link: `/${moduleSlug}/config/activities`,
    new: true,
    permission: "Loyalty.Dashboard",
  },
  {
    name: 'Enrollment',
    link: `/${moduleSlug}/config/card-enrollment`,
    new: true,
    brandFlag: 'enableCardEnrollment',
    permission: "Loyalty.Config.Enrollment",
  },
  {
     name: 'Fraud Rules',
     link: `/${moduleSlug}/config/fraud/rules`,
     permission: "Loyalty.Config.FraudDetection",
     new: true,
  },
  {
    name: 'v1 Configurations',
    link: actionUrl + 'merchant/:id/loyalty',
    permission: "Loyalty.Config.Loyalty",
  },
  {
    name: 'v1 Programs',
    link: actionUrl + 'merchant/:id/multi-loyalty',
    permission: "Loyalty.Config.Loyalty",
  },
];

export const picker = LoyaltyPicker;